.testimonial {

	.tiles-item-inner {
		// background: get-color(dark, 2);		
		background: #023853;
        box-shadow: 0px 0px 50px #023853;
	}
}

.testimonial-item-content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 18px;
        // margin-top: 12px;
        // margin-bottom: 16px;
        // background-image: inline-svg('<svg width="24" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z" fill="' + get-color(primary, 1) + '" fill-rule="nonzero"/></svg>');
        background-repeat: no-repeat;
    }		
}

.testimonial-item-footer {
    font-weight: 600;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.has-top-divider {

        &::before {
            background: get-color(light, 2);
        }
    }
}

.testimonial-item-link {
	color: get-color(secondary, 2);

	// a {
    //     color: get-color(secondary, 3);
    //     text-decoration: none;

    //     &:hover {
    //         color: get-color(secondary, 2);
    //     }
	// }
}